import PropTypes from "prop-types";

function IconSearch({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            clipRule="evenodd"
            fillRule="evenodd"
            {...rest}
        >
            <path d="m39.7 37.9-.7-.7c2.6-3 4.1-6.9 4.1-11.1 0-9.5-7.6-17.1-17-17.1s-17.1 7.6-17.1 17.1 7.6 17.1 17.1 17.1c4.2 0 8.1-1.5 11.1-4.1l.7.7v2.1l12.3 12.2c.5.5 1.3.5 1.8 0l2.2-2.2c.5-.5.5-1.3 0-1.8l-12.4-12.2zm-25.7-12.1c0-6.6 5.2-11.8 11.8-11.8s11.8 5.3 11.8 11.8-5.3 11.8-11.8 11.8-11.8-5.3-11.8-11.8z" />
        </svg>
    );
}

IconSearch.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconSearch.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconSearch;
