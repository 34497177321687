import PropTypes from "prop-types";

function IconGps({ fill, size, ...rest }) {
    return (
        <svg
            enableBackground="new 0 0 64 64"
            viewBox="0 0 64 64"
            width={size}
            height={size}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={fill}
            {...rest}
        >
            <path d="m32 39c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zm-16.9-9.4c1.1-7.6 7.1-13.6 14.7-14.7v-3.4c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v3.4c7.5 1.1 13.4 7.1 14.5 14.7h2.2c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5h-2.2c-1.1 7.6-7.1 13.6-14.6 14.7v3.3c0 1.4-1.1 2.5-2.5 2.5s-2.5-1.1-2.5-2.5v-3.3c-7.6-1.1-13.6-7.1-14.6-14.7h-2.6c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5zm17.1 14.9c6.8 0 12.3-5.5 12.3-12.4s-5.5-12.4-12.3-12.4-12.3 5.5-12.3 12.4 5.5 12.4 12.3 12.4z" />
        </svg>
    );
}

IconGps.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconGps.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconGps;
