import { generateSlug } from "./utils";

const addressFields = {
    locality: "long_name",
    administrative_area_level_1: "short_name",
    political: "long_name"
};

const translations = {
    locality: "city",
    administrative_area_level_1: "province",
    political: "country"
};

export function formatPlaceDetails(addressComponents) {
    const location = {};

    addressComponents.forEach(element => {
        const type = element.types[0];

        if (type in addressFields) {
            location[translations[type]] = element[addressFields[type]];
        }
    });

    return location;
}

export function formatPlaceSuggestion(terms, types) {
    const location = {};

    types.forEach((element, index) => {
        const type = types[index];

        if (type in addressFields && terms?.[index]?.value) {
            location[translations[type]] = terms[index].value;
        }
    });

    return location;
}

export function parseMerchants(data) {
    const merchants = data?.merchants?.edges || [];

    return merchants.map(({ id, title, slug, address }) => ({
        id,
        slug,
        type: "merchant",
        description: title,
        location: {
            province: address?.provinceCode?.toLowerCase(),
            city: address?.city
        }
    }));
}

export function parseLocations(data) {
    const locations = data?.predictions || [];

    // eslint-disable-next-line camelcase
    return locations.map(({ place_id, description, terms, types }) => {
        const location = formatPlaceSuggestion(terms, types);

        return {
            id: place_id,
            type: "suggestion",
            description,
            ...location
        };
    });
}

export function parseGeolocation(data) {
    const suggestions = data || [];

    return (
        suggestions
            .filter(address => address.address_components.length > 4)
            // eslint-disable-next-line camelcase
            .map(({ address_components, place_id, formatted_address }) => {
                const location = formatPlaceDetails(address_components);

                return {
                    id: place_id,
                    type: "location",
                    description: formatted_address,
                    location
                };
            })
    );
}

export function slugifyLocation(location, slug) {
    const parts = [];

    if (location?.province) {
        parts.push(location.province);
    }

    if (location?.city) {
        parts.push(location.city);
    }

    if (slug) {
        parts.push(slug);
    }

    const params = parts.map(component => {
        return generateSlug(component);
    });

    return `/waar-te-besteden/tickets-aan-de-kassa/${params.join("/")}/`;
}
