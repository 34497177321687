import styled, { keyframes, css } from "styled-components";

export const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const pulsate = keyframes`
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
`;

export const Location = styled.button`
    ${({ position }) =>
        (position === "inline" || position === "right") &&
        css`
            position: absolute;
            top: 0;

            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            svg {
                fill: var(--color-primary);
            }
        `}

    ${({ position }) =>
        position === "inline" &&
        css`
            right: 40px;

            width: 40px;
        `}

    ${({ position }) =>
        position === "right" &&
        css`
            right: 0;

            width: 50px;
        `}

    ${({ position }) =>
        position === "top" &&
        css`
            display: inline-flex;
            gap: 0 5px;
            align-items: center;
        `}

    z-index: 4;

    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: var(--line-height-body);
    text-align: center;

    background: transparent;
    border: none;
    cursor: pointer;

    transition: all var(--animation-duration-default) ease;

    svg {
        transition: all var(--animation-duration-default) ease;
    }

    @media (any-hover: hover) {
        :hover {
            color: var(--color-secondary);

            svg {
                fill: var(--color-secondary);
            }
        }
    }

    ${({ isLoading }) =>
        isLoading &&
        css`
            > svg {
                animation: ${rotate} 2s var(--animation-easing-standard)
                    infinite;
                fill: var(--color-secondary);
            }

            animation: ${pulsate} 1s var(--animation-easing-standard) infinite;
        `}
`;
